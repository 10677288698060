@import "../../_shared/colors.scss";

@media screen and (min-width: 1px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }

  .header-container__title {
    color: $core-teal;
    font-size: 2.6rem;
    font-weight: 400;
    margin: 37px 0 0 0;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .header-container__title {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
}

.shadow {
  text-shadow: inherit;
}

.no-shadow {
  text-shadow: none;
}

.white {
  color: $white;
}

.teal {
  color: $core-teal;
}
