@import "../../../../_shared/theme.scss";

:global {
  @media only screen and (min-width: 1px) {
    .MuiTab-root {
      font-family: "Open Sans";
      // font-size: 13px;
      font-weight: 600;
      padding: 0;
    }

    .MuiTabs-flexContainer {
      justify-content: space-around;
    }

    .MuiTab-textColorInherit {
      color: #a3a9ac;
      font-style: normal;
      text-shadow: 0px 0px 20px rgba(0, 75, 80, 0.07);
    }

    .Mui-selected {
      color: #0095a0;
    }
  }
}

@media only screen and (min-width: 1px) {
  .container {
  }

  .refresh{
    margin: 20px auto 10px;
    width: 50%;
    height: 50px;
    border: none;
    border-radius: 4px;
    background-color: #0095a0;
    color: white;
  }

  .header {
    align-items: baseline;
    box-shadow: 0px 4px 10px rgba(0, 75, 80, 0.03);
    display: flex;
    // padding: 24px;

    .chevron-container {
      display: flex;
      align-items: center;
      padding: 32px 0 32px 24px;
      cursor: pointer;

      img {
        display: block;
        margin-right: 24px;
        transform: rotate(180deg);
      }
    }

    h1 {
      color: #373d43;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tabs {
    border-bottom: 1px solid #f6f6f6;
    padding: 24px 24px 0 24px;

    .tab {
      font-size: 14px;

      &.active {
      }

      &.not-active {
        color: $typography-medium;
      }
    }
  }

  .requests-empty-state {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0 0 0;

    img {
      margin: 0 0 44px 0;
    }

    p {
      margin: 0;
      font-weight: 300;
      line-height: 23px;
      color: #000;
    }
  }

  .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px 0;

    img {
      width: 50%;
      margin: 8px 0;
    }
  }

  .tab-item {
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    outline: none;
    padding: 0 0 8px 0;
    text-shadow: 0px 0px 20px rgba(0, 75, 80, 0.07);
    text-transform: uppercase;

    &.not-active {
      color: #a3a9ac;
    }

    &.active {
      color: #0095a0;
    }
  }

  .filter__container {
    display: flex;
    > div {
      width: 100%;

      &:first-of-type {
        padding-right: 0;
      }

      &:last-of-type {
        padding-left: 8px;
      }
    }
  }

  .filters {
    display: flex;
    justify-content: space-around;
    margin: 32px 0 8px 0;

    button {
      background: none;
      border: 1px solid #eceded;
      border-radius: 20px;
      font-size: 14px;
      height: 40px;
      line-height: 22px;
      outline: none;
      width: 80px;

      &.active {
        background: #fafbfb;
        border: 1px solid #4cb5bd;
        font-weight: 600;
      }
    }
  }

  .select {
    margin-top: 32px;
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: center;

    p {
      margin-right: 24px;
      color: $typography-medium;
      font-weight: 600;
      font-size: 14px;
    }

    .select-dropdown {
      width: 80%;

      &:focus {
        outline: none;
        border: 1px solid #eceded;
      }
    }
  }

  .list {
    padding: 24px 0;

    .list-item {
      background: #fafbfb;
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 8px 24px;
      color: $typography-dark;

      &:hover {
        color: $core-teal;
        text-decoration: none;
      }

      &:last-of-type {
        margin: 0;
      }

      .icon {
        margin-right: 10px;
        visibility: visible;
      }

      .item-details {
        display: flex;
        align-items: center;
        height: 50px;
        p {
          margin: 0;
        }

        .title {
          font-size: 15px;
          font-weight: 600;
          line-height: 23px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .multiline-title {
          font-size: 15px;
          font-weight: 600;
          line-height: 23px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // width: 200px;
        }

        .approver {
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 8px;
        }

        .time-stamp {
          color: #a3a9ac;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          margin-top: 4px;
          // height: 22px;
        }
      }

      .chevron {
        align-self: center;

        img {
          // transform: rotate(180deg);
        }
      }
    }
  }
}
