@import "../../_shared/colors.scss";

:global {
  i.fa-ellipsis {
    position: relative;
  }
}

@media only screen and (min-width: 1px) {
  .container {
    // padding-left: 20px;
    // padding-right: 20px;
    // height: 70px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // position: relative;
  }

  .sidenav-opacity {
    background: rgb(0%, 0%, 0%);
    height: calc(100vh - 70px);
    opacity: 0.6;
    width: 90px;
    // z-index: 5000;
  }

  .inner_container {
    background: rgb(0%, 0%, 0%);
    height: 70px;
    opacity: 0.6;
    width: 100vw;
  }

  .light_mode {
    background: #fafbfb;
    height: 75px;
    opacity: 0.5;
    width: 100vw;
    border: none;
  }

  .dark_mode {
    background: #383a3a;
    height: 75px;
    // opacity: 0.5;
    width: 100vw;
    border: none;
  }

  .dark_container {
    background: #383a3a;
  }

  .light_container {
    background: #fafbfb;
  }

  .light_icon_container {
    color: #a3a9ac;
    position: absolute;
  }

  .light_icon {
    color: #c8cbcd;
  }

  .iconContainer {
    color: white;
    // display: flex;
    // justify-content: space-between;
    position: absolute;
  }

  .scrim {
    height: calc(100vh - 70px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1;
  }

  .sidenav_lg {
    left: 90px;
    position: absolute;
    top: 0;
    z-index: 4;
    .sidenav-opacity {
      background: rgb(0%, 0%, 0%);
      height: calc(100vh - 70px);
      opacity: 0.5;
      width: 210px;
    }
  }

  .sidenav_lg__items {
    font-size: 1.6rem;
    font-weight: 400;
    left: -90px;
    list-style-type: none;
    padding: 0;
    position: absolute;
    text-transform: uppercase;
    top: 80px;
    width: 100vw;
    li {
      margin: 0;
      width: 100%;
    }
    a {
      color: $white;
      display: block;
      padding: 11px 0 11px 23px;
    }
  }

  .imgLogo {
    height: 35px;
    width: auto;
  }

  .badge {
    left: -6px;
    position: absolute;
    top: -3px;
    width: 23px;
  }

  .submenu {
    background: #000;
    left: -70px;
    opacity: 0.3;
    position: absolute;
    top: 56px;
    width: 200px;
  }

  .searchBoxPositioning {
    position: absolute;
    right: 0px;
    top: -3px;
  }

  .elipsis {
    position: absolute;
    right: 1px;
    top: 0px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fadeInText {
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
  }

  .pending-actions-icon {
    position: absolute;
    right: 25;
    top: 21;
    // z-index: 5;
  }
}

@media only screen and (min-width: 768px) {
  .searchBoxPositioning {
    position: absolute;
    right: 20px;
    top: -9px;
  }
}

@media only screen and (min-width: 992px) {
}
