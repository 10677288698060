@import "../../_shared/colors.scss";

.loginContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // left: 50%;
  // position: absolute;
  // top: 50%;
  // transform: translate(-50%, -60%);
}

.greetings {
  p {
    color: $white;
    font-size: 30px;
    margin: 0 0 40px 0;
    text-align: center;
  }
}

.search {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // font-size: 12px;
  // position: relative;
  // text-align: center;
  // width: 300px;

  * {
    margin: 3%;
  }
  input[type="text"] {
    font-weight: 600;
    box-sizing: border-box;
  }

  input[type="text"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type="text"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  .search-icon {
    color: #a3a9ac;
    font-size: 18px;
    font-weight: 600;
    left: 17px;
    position: absolute;
    top: 13px;
  }

  .search-bar {
    border: none;
    border-radius: 20px;
    height: 44px;
    padding: 17px;
    width: 250px;
    box-sizing: border-box;

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  .loginButton {
    background-color: #0095a0;
    border: none;
    border-radius: 20px;
    color: white;
    height: 44px;
    width: 200px;
  }

  .resetButton {
    color: #fff7f7;
    background-color: #e55353;
    border: none;
    outline: none;
    height: 44px;
    width: 200px;
    border-radius: 20px;
  }
}

.forgotPwText {
  text-align: center;
}

.forgotPwText > a {
  color: white;
}

  .authContainer {

    p {
      text-shadow: none;
    }
  }

// @media only screen and (min-width: 768px) {
//   .search {
//     width: 500px;
//   }
// }
