.overlayContainer {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px 8px 8px 8px;
  padding: 4px 0px 0px 0px;
  position: absolute;
  z-index: 10;
}

.overlayContainer::before {
  content: "World Clock";
  border-bottom: 0.5px solid #fff;
  color: #fff;
  font-size: 1.6rem;
  margin: 5px 15px 5px 15px;
  text-align: left;
}

.card {
  // background-color: rgba(0, 0, 0, 0.55);
  border-radius: 6px;
  color: #fff;
  margin: 4px 8px;
  padding: 7px;
  width: 200px;
  p {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 0.1rem;
  }
}

.card:last-child {
  margin-bottom: 8px;
}

// .card:first-child {
//   background-color: black;
// }
