:global {
  /* ===========================
  MARGIN
  ============================= */
  .m-0 {
    margin: 0px;
  }
  .m-2 {
    margin: 2px;
  }
  .m-4 {
    margin: 4px;
  }
  .m-8 {
    margin: 8px;
  }
  .m-12 {
    margin: 12px;
  }
  .m-16 {
    margin: 16px;
  }
  .m-20 {
    margin: 20px;
  }
  .m-24 {
    margin: 24px;
  }

  // margin top
  .mt-0 {
    margin-top: 0px;
  }
  .mt-2 {
    margin-top: 2px;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mt-12 {
    margin-top: 12px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-24 {
    margin-top: 24px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-48 {
    margin-top: 48px;
  }

  // margin bottom
  .mb-0 {
    margin-bottom: 0px;
  }
  .mb-2 {
    margin-bottom: 2px;
  }
  .mb-4 {
    margin-bottom: 4px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  // margin left
  .ml-0 {
    margin-left: 0px;
  }
  .ml-2 {
    margin-left: 2px;
  }
  .ml-4 {
    margin-left: 4px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .ml-12 {
    margin-left: 12px;
  }
  .ml-16 {
    margin-left: 16px;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-24 {
    margin-left: 24px;
  }

  // margin right
  .mr-0 {
    margin-right: 0px;
  }
  .mr-2 {
    margin-right: 2px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mr-24 {
    margin-right: 24px;
  }

  // margin horizontal
  .mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-12 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .mx-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mx-24 {
    margin-left: 24px;
    margin-right: 24px;
  }

  // margin vertical
  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  /* ===========================
  PADDING
  ============================= */
  .p-0 {
    padding: 0px;
  }
  .p-2 {
    padding: 2px;
  }
  .p-4 {
    padding: 4px;
  }
  .p-8 {
    padding: 8px;
  }
  .p-12 {
    padding: 12px;
  }
  .p-16 {
    padding: 16px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-24 {
    padding: 24px;
  }

  // padding top
  .pt-0 {
    padding-top: 0px;
  }
  .pt-2 {
    padding-top: 2px;
  }
  .pt-4 {
    padding-top: 4px;
  }
  .pt-8 {
    padding-top: 8px;
  }
  .pt-12 {
    padding-top: 12px;
  }
  .pt-16 {
    padding-top: 16px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pt-24 {
    padding-top: 24px;
  }

  .pt-32 {
    padding-top: 32px;
  }

  // padding bottom
  .pb-0 {
    padding-bottom: 0px;
  }
  .pb-2 {
    padding-bottom: 2px;
  }
  .pb-4 {
    padding-bottom: 4px;
  }
  .pb-8 {
    padding-bottom: 8px;
  }
  .pb-12 {
    padding-bottom: 12px;
  }
  .pb-16 {
    padding-bottom: 16px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .pb-24 {
    padding-bottom: 24px;
  }

  .pb-32 {
    padding-bottom: 32px;
  }

  // padding left
  .pl-0 {
    padding-left: 0px;
  }
  .pl-2 {
    padding-left: 2px;
  }
  .pl-4 {
    padding-left: 4px;
  }
  .pl-8 {
    padding-left: 8px;
  }
  .pl-12 {
    padding-left: 12px;
  }
  .pl-16 {
    padding-left: 16px;
  }
  .pl-20 {
    padding-left: 20px;
  }
  .pl-24 {
    padding-left: 24px;
  }

  // padding right
  .pr-0 {
    padding-right: 0px;
  }
  .pr-2 {
    padding-right: 2px;
  }
  .pr-4 {
    padding-right: 4px;
  }
  .pr-8 {
    padding-right: 8px;
  }
  .pr-12 {
    padding-right: 12px;
  }
  .pr-16 {
    padding-right: 16px;
  }
  .pr-20 {
    padding-right: 20px;
  }
  .pr-24 {
    padding-right: 24px;
  }

  // padding horizontal
  .px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  // padding vertical
  .py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
