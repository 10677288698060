@import "../../_shared/colors.scss";

:global {
  .fa-megaphone,
  .fa-clipboard-list-check,
  .fa-exclamation-triangle,
  .fa-book {
    position: relative;
    font-size: 24px !important;
    text-shadow: none;
    &:hover {
      cursor: pointer;
    }

    span.badge {
      background-color: #ff5c5c;
      border-radius: 8px;
      color: #ffffff;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 12px;
      font-weight: 600;
      left: 10px;
      padding: 2px 5px;
      position: absolute;
      top: -8px;

      &.service-alerts {
        padding: 0px 4px;
      }
    }

    span.badge-newsroom {
      background-color: #4da1a8;
      border-radius: 8px;
      color: #ffffff;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 12px;
      font-weight: 600;
      left: 10px;
      padding: 2px 5px;
      position: absolute;
      top: -8px;

      &.service-alerts {
        padding: 0px 4px;
      }
    }
  }

  span.newsroom-notification {
    background-color: #ff5c5c;
    border-radius: 8px;
    color: #ffffff;
    height: 16px;
    width: 16px;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    left: 18px;
    // padding: 2px 5px;
    position: absolute;
    top: -8px;

    &.service-alerts {
      padding: 0px 4px;
    }
  }

  .fa-megaphone,
  .fa-exclamation-triangle {
    margin: 0px 10.5px !important;
  }
  .fa-clipboard-list-check {
    margin: 0px 15px !important;
  }
  .fa-book {
    margin: 0px 13.5px !important;
  }

  .searchBarContainer .styles_modal__gNwvD {
    border-top: 6px solid $core-teal;
    position: absolute;
    right: 0;
    top: 70px;
  }
}

.search-bar-icon {
  display: flex;
  span.badge {
    background-color: #ff5c5c;
    border-radius: 8px;
    color: #ffffff;
    font-family: Open Sans, Arial, sans-serif;
    font-size: 5px;
    font-weight: 600;
    padding: 2px 5px;
    right: 53px;
    position: absolute;
  }
}

.announcementModal {
  border-top: 7px solid $core-teal;
  min-height: 75vh;
  min-width: 500px;
  overflow: hidden;
  position: absolute;
  right: 138px;
  top: 63px;
  user-select: none;

  .featured-content {
    svg {
      position: absolute;
      right: 45%;
      top: 40%;
    }
  }
}

.pendingActionModal {
  border-top: 7px solid $core-teal;
  max-width: 500px;
  position: absolute;
  right: 100px;
  top: 65px;
}

.modalOverlay {
  background: rgba(0, 0, 0, 0.6);
}

.noModalOverlay {
  background: none;
}

.closeButton {
  top: 21px;
}

.modal-title {
  color: #0095a0;
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 25px 15px;
}

.white {
  color: white;
}

.grey {
  color: #a3a9ac;
}

span.total-pending {
  background-color: #ff5c5c;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  padding: 2px 15px;
  position: relative;
  top: -4px;
  vertical-align: bottom;
}

@media screen and (min-width: 1px) {
  .reddot {
    background-color: #ff5c5c;
    border-radius: 6px;
    color: #ffffff;
    font-size: 23px;
    padding: 5px 5px;
    position: absolute;
  }
  .searchBarContainer {
    align-items: center;
    display: flex;
    // margin: 10px 0 0 0;
    position: relative;
    right: 0px;
    top: 12px;
    // left: 606px;
    form {
      margin: 0;
    }
  }

  .search-bar {
    border-radius: 20px;
    height: 44px;
    padding: 12px;
  }

  .search-icon {
    font-size: 26px;
    font-weight: 300;
  }

  .search-bar-container__input {
    border-radius: 30px;
    position: relative;
    width: 450px;
  }

  .search-bar-container__search-icon {
    position: absolute;
    right: 140px;
  }

  .search-bar-container__icon {
    margin: 0 12px;
    color: rgb(114, 118, 120);
    opacity: 0.75;
  }
  .search-bar-container-shadow__icon {
    margin: 0 12px;
    filter: drop-shadow(2px 4px 6px rgba(55, 61, 67, 0.4));
  }

  .settings-box {
    align-items: center;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    height: 24px;
    justify-content: center;
    width: 24px;
    text-shadow: none;
    margin: 0 12px;

    span {
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      text-align: center;
      // z-index: 5;
    }
  }

  .user-profile {
    background-color: #4da1a8;
    span {
      color: white;
    }
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .index--time-date {
    // border-right: 1px solid white;
    // bottom: 120px;
    color: white;
    font-size: 35px;
    // margin-right: -21px;
    // left: -15px;
    position: absolute;
    right: 54px;
    top: 74px;
    // transform: rotate(90deg);
    width: 100px;
  }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1500px) {
}

// @import "../PendingActions/PendingActions.scss";
