@import "../../_shared/colors.scss";

.spinner-container {
  width: initial;

  @supports (display: grid) {
    width: 100%;
  }
}

.loading {
  display: block;
  margin: auto;
  width: 3.75em;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.circle {
  fill: none;
  stroke: $bright-teal;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
