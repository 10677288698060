@import "../../_shared/colors.scss";

.BGcontainer {
  top: 16px;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
}

.noBGcontainer {
  top: 30px;
}

.textShadow {
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 1px) {
  .container {
    overflow: auto;
    position: absolute;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    height: 100vh;
    overflow: auto;
    padding: 0;
    position: absolute;
  }
}

.hideScrollbar {
  overflow-x: hidden;
}

.container {
  overflow: auto;
  // overflow-x: hidden;
  position: absolute;
}

.greyBackground {
  background: #fafbfb;
}

.appContainer {
  height: 100vh;
}
